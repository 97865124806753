<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView } from '@cargill/shared'
import service from '../../api/weeklyDemandAttendanceReportService'

export default {
  components: { CargillCrudMetaView },
  name: 'WeeklyDamandAttendanceReport',
  data() {
    return {
      metadata: null,
      crudService: service
    }
  },
  created() {
    service.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
